$navbar-logo-height: 35px !default;
$footer-logo-height: 35px !default;

$utilities: map-merge(
        $utilities, (
        "width": map-merge(
                map-get($utilities, "width"),
                (responsive: true),
        ),
    )
);

html,
body {
    overflow-x: hidden !important;
    padding-right: 0 !important;
    touch-action: pan-x pan-y;
}

p {
    margin-bottom: 0;

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6,
    + ol,
    + ul,
    + p {
        margin-top: $paragraph-margin-bottom;
    }
}

input[type="date"] {
    display: inline-block;
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: transparent;
    width: auto;
    height: auto;
    cursor: pointer;
    position: absolute;
    background: transparent;
}

input[type="date"]::-webkit-datetime-edit-day-field:focus,
input[type="date"]::-webkit-datetime-edit-month-field:focus,
input[type="date"]::-webkit-datetime-edit-year-field:focus {
    background-color: transparent;
}

p {
    ol {
        li {
            list-style: none;
            padding-left: 10px;

            &::before {
                position: relative;
                content: "\F26B";
                top: 4px;
                right: 10px;
                font-size: 18px;
                color: $secondary;
                font-family: "bootstrap-icons", serif !important;
            }

            & + li {
                margin-top: map-get($spacers, 1);
            }
        }
    }
}

.form-select,
.form-control {
    &.is-valid,
    &.is-invalid {
        background-size: 18px !important;
        box-shadow: unset !important;
    }

    &.is-invalid {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23f57e77' class='bi bi-x-circle-fill' viewBox='0 0 16 16'%3E%3Cpath d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'/%3E%3C/svg%3E") !important;
    }

    &.is-valid {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%2300de8b' class='bi bi-check-circle-fill' viewBox='0 0 16 16'%3E%3Cpath d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'/%3E%3C/svg%3E") !important;
    }

    &.is-loading {
        background-image: unset !important;
    }

    ~ label {
        width: 100%;
    }
}

.form-control-spinner {
    position: absolute;
    top: 50%;
    margin-top: 2px;
    right: 1rem;
    transform: translate(0, -50%);

    .spinner-border-sm {
        width: 1.1rem;
        height: 1.1rem;
    }
}

.form-check {
    &.is-valid {
        color: $body-color !important;

        label {
            color: inherit !important;

            span {
                color: inherit !important;
            }
        }
    }

    .is-valid {
        color: $body-color !important;

        + label {
            color: inherit !important;

            span {
                color: inherit !important;
            }
        }
    }
}

.bg-gradient {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.form-range-gray {
    &[type=range]::-webkit-slider-runnable-track {
        background-color: $gray-300;
    }

    &[type=range]:focus::-webkit-slider-runnable-track {
        background-color: $gray-300;
    }

    &[type=range]::-moz-range-track {
        background-color: $gray-300;
    }
}

.btn-form {
    color: $input-color;
}

.btn-outline-form {
    padding-top: 16px;
    padding-bottom: 16px;
    border-color: $input-border-color;

    &:hover {
        border-color: $input-focus-border-color;
    }

    input[type="radio"] ~ & {
        &:hover {
            border-color: $dark;
        }
    }

    input[type="radio"]:checked ~ & {
        border-color: $secondary;
        background-color: $secondary;
    }
}

.btn[disabled] {
    filter: grayscale(1);
    opacity: 1;
}

.dropdown-datetime {
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: left;

    &.btn-outline {
        border-color: $input-border-color;

        small {
            opacity: $form-floating-label-opacity;
        }
    }

    &.dropdown-toggle::after {
        display: unset !important;
        content: "" !important;
        border: 0 !important;
        margin: 0 !important;
        vertical-align: 0 !important;
    }
}

.dropdown-menu-datetime {
    min-width: unset;
    max-height: 300px;
    overflow-y: auto !important;
    border-radius: $border-radius !important;
}

.w-33 {
    width: calc(100% / 3) !important;
}

.text-capitalize-first-letter:first-letter {
    text-transform: uppercase;
}

.currency-unit {
    display: inline-flex;
}

.tooltip-helper .tooltip-inner {
    border-radius: 6px !important;
}

.bg-header {
    top: 0;
    left: 0;
    position: absolute;
    user-select: none;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
    }
}
